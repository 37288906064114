import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import { NewTheme } from "../../../Theme/Theme";
import { BoldText, FlexItem } from "../Styled";
import Php from "../../../../Backend/Php";

const php = new Php();

export default function MultiplierModal(props) {
  const [futMultiplier, setFutMultiplier] = React.useState(
    props.fut ? props.fut : 0
  );
  const [mcxMultiplier, setMcxMultiplier] = React.useState(
    props.mcx ? props.mcx : 0
  );
  const [foMultiplier, setFoMultiplier] = React.useState(
    props.fo ? props.fo : 0
  );
  const [forexMultiplier, setForexMultiplier] = React.useState(
    props.forex ? props.forex : 0
  );
  const [cryptoMultiplier, setCryptoMultiplier] = React.useState(
    props.crypto ? props.crypto : 0
  );
  const [stocksMultiplier, setStocksMultiplier] = React.useState(
    props.stocks ? props.stocks : 0
  );
  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() =>
          props.handleUsersMultiplier(
            futMultiplier,
            mcxMultiplier,
            foMultiplier,
            forexMultiplier,
            cryptoMultiplier,
            stocksMultiplier
          )
        }
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={<div style={{ color: NewTheme.MainColor }}>Edit Multiplier</div>}
      visible={props.visible}
      position={props.position}
      style={{ width: "35vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Future</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 35 }}>
        <span className="p-inputgroup-addon">{props.masterMin}</span>
        <InputText
          value={futMultiplier}
          onChange={(e) => setFutMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">{props.masterMax}</span>
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Mcx</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 35 }}>
        <span className="p-inputgroup-addon">{props.masterMin}</span>
        <InputText
          value={mcxMultiplier}
          onChange={(e) => setMcxMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">{props.masterMax}</span>
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Options</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 35 }}>
        <span className="p-inputgroup-addon">{props.masterMin}</span>
        <InputText
          value={foMultiplier}
          onChange={(e) => setFoMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">{props.masterMax}</span>
      </div>
      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Forex</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        <span className="p-inputgroup-addon">{props.masterMin}</span>
        <InputText
          value={forexMultiplier}
          onChange={(e) => setForexMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">{props.masterMax}</span>
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Crypto</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        <span className="p-inputgroup-addon">{props.masterMin}</span>
        <InputText
          value={cryptoMultiplier}
          onChange={(e) => setCryptoMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">{props.masterMax}</span>
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>US Stocks</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        <span className="p-inputgroup-addon">{props.masterMin}</span>
        <InputText
          value={stocksMultiplier}
          onChange={(e) => setStocksMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">{props.masterMax}</span>
      </div>
    </Dialog>
  );
}
