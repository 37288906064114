import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { BalanceInput, BalanceLabel } from "../Add/AddUser";
import { FlexItem } from "../Overview/Styled";
import { Dropdown } from "primereact/dropdown";

const php = new Php();

export default function CopyModal(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [clientList, setClientList] = React.useState([]);
  const [clientId, setClientId] = React.useState(0);

  React.useEffect(() => {
    load_masters_clients();
  }, []);

  const load_masters_clients = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
      };

      php.load_users(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientList(r.users);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const load_copy_scripts = (e) => {
    console.log(clientId);
    if (
      clientId?.id <= 0 ||
      clientId?.id == props.client ||
      clientId?.id == undefined
    ) {
      window.alert("Invlaid Client. Please select properly");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          m_id: localStorage.getItem("mastersId"),
          c_id: props.client,
          copy_id: clientId?.id,
        };

        php.load_copy_scripts(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            props.reload();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="danger"
        onClick={() => props.setVisible(false)}
      />
      <Button
        label="Copy "
        icon="pi pi-plus"
        onClick={() => load_copy_scripts()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={<div style={{ color: NewTheme.MainColor }}>Copy Scripts</div>}
      visible={props.visible}
      position={props.position}
      style={{ width: "40vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BalanceLabel>Client</BalanceLabel>
      </FlexItem>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Dropdown
          style={{
            height: "40px",
          }}
          onChange={(e) => {
            setClientId(e.value);
          }}
          options={clientList}
          value={clientId}
          optionLabel="username"
          placeholder="Select Username"
          filter
          showClear
        />
      </div>
    </Dialog>
  );
}
