import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { NewTheme } from "../../../Theme/Theme";
import {
  BoldText,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
} from "../Styled";
import Php from "../../../../Backend/Php";
import { useNotification } from "../../../Notification/NotificationContext";
import NumberFormatIn from "../../../Common/NumberFormatIn";

const php = new Php();

export default function LiveSummaryModal(props) {
  const { addNotification } = useNotification();
  const [activeSummary, setActiveSummary] = useState("");
  const [totalPL, setTotalPL] = useState("");
  const [marginFut, setMarginFut] = useState("");
  const [marginMcx, setMarginMcx] = useState("");
  const [marginFo, setMarginFo] = useState("");
  const [marginForex, setMarginForex] = useState("");
  const [marginCrypto, setMarginCrypto] = useState("");
  const [marginStocks, setMarginStocks] = useState("");

  React.useEffect(() => {
    loadActiveSummary();
  }, [props.usersId]);

  const loadActiveSummary = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      m_id: localStorage.getItem("mastersId"),
      c_id: props.usersId,
    };

    php.load_users_active_summary(data).then((r) => {
      if (r.error == "False") {
        setActiveSummary(r.data);
        let PL = parseFloat(props.weekly_pl) + parseFloat(r.data);

        let margin_fut = parseFloat(r.data) + parseFloat(props.deposit);
        margin_fut = margin_fut * parseFloat(props.fut_multiplier);
        margin_fut = margin_fut - parseFloat(props.blocked_limit);

        //mcx
        let margin_mcx = parseFloat(r.data) + parseFloat(props.deposit);
        margin_mcx = margin_mcx * parseFloat(props.mcx_multiplier);
        margin_mcx = margin_mcx - parseFloat(props.blocked_limit);
        //fo
        let margin_fo = parseFloat(r.data) + parseFloat(props.deposit);
        margin_fo = margin_fo * parseFloat(props.fo_multiplier);
        margin_fo = margin_fo - parseFloat(props.blocked_limit);
        //forex
        let margin_forex = parseFloat(r.data) + parseFloat(props.deposit);
        margin_forex = margin_forex * parseFloat(props.forex_multiplier);
        margin_forex = margin_forex - parseFloat(props.blocked_limit);
        //crypto
        let margin_crypto = parseFloat(r.data) + parseFloat(props.deposit);
        margin_crypto = margin_crypto * parseFloat(props.crypto_multiplier);
        margin_crypto = margin_crypto - parseFloat(props.blocked_limit);
        //stocks
        let margin_stocks = parseFloat(r.data) + parseFloat(props.deposit);
        margin_stocks = margin_stocks * parseFloat(props.stocks_multiplier);
        margin_stocks = margin_stocks - parseFloat(props.blocked_limit);
        // set
        setTotalPL(parseFloat(PL).toFixed(0));
        setMarginFut(parseFloat(margin_fut).toFixed(0));
        setMarginMcx(parseFloat(margin_mcx).toFixed(0));
        setMarginFo(parseFloat(margin_fo).toFixed(0));
        setMarginForex(parseFloat(margin_forex).toFixed(0));
        setMarginCrypto(parseFloat(margin_crypto).toFixed(0));
        setMarginStocks(parseFloat(margin_stocks).toFixed(0));
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      {/* <Button
        label="PDF"
        icon="pi pi-download"
        onClick={() => alert("PDF")}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      /> */}
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"  Client Live Summary : "}
          {props.clientUsername != undefined ? props.clientUsername : null}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <FlexContainer>
          <FlexItem>
            <GrayText>Booked P/L</GrayText>
            <GreenText
              style={{
                color:
                  props.weekly_pl >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={props.weekly_pl} />
            </GreenText>
          </FlexItem>
          <FlexItem>
            <GrayText>Live P/L</GrayText>
            <GreenText
              style={{
                color:
                  activeSummary != null && parseFloat(activeSummary) > 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn
                value={
                  activeSummary == null
                    ? 0
                    : parseFloat(activeSummary).toFixed(2)
                }
              />
            </GreenText>
          </FlexItem>
          <FlexItem>
            <GrayText>Total P/L</GrayText>
            <GreenText
              style={{
                color:
                  totalPL != null && parseFloat(totalPL) > 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn
                value={
                  totalPL == null || isNaN(totalPL) ? 0 : parseFloat(totalPL)
                }
              />
            </GreenText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Future Limit</GrayText>
            <BoldText>
              <NumberFormatIn value={marginFut ? parseFloat(marginFut) : 0} />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Mcx Limit</GrayText>
            <BoldText>
              <NumberFormatIn value={marginMcx ? parseFloat(marginMcx) : 0} />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Option Limit</GrayText>
            <BoldText>
              <NumberFormatIn value={marginFo ? parseFloat(marginFo) : 0} />
            </BoldText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Forex Limit</GrayText>
            <BoldText>
              <NumberFormatIn
                value={marginForex ? parseFloat(marginForex) : 0}
              />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Crypto Limit</GrayText>
            <BoldText>
              <NumberFormatIn
                value={marginCrypto ? parseFloat(marginCrypto) : 0}
              />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>US Stocks Limit</GrayText>
            <BoldText>
              <NumberFormatIn
                value={marginStocks ? parseFloat(marginStocks) : 0}
              />
            </BoldText>
          </FlexItem>
        </FlexContainer>
      </>
    </Dialog>
  );
}
