import React, { useState } from "react";
import styled from "styled-components";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "./Styled";
import { NewTheme } from "../../Theme/Theme";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { Button } from "primereact/button";
import CopyToClipboard from "react-copy-to-clipboard";

const php = new Php();

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const EditButton = styled.button`
  padding: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export default function Overview(props) {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_brokers_overview();
  }, [props.data.id]);

  const load_brokers_overview = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
        b_id: props.data.id,
      };

      php.load_brokers_overview(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setClientData(r.broker_overview[0]);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleStatusButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        m_id: localStorage.getItem("mastersId"),
        b_id: clientData.id,
      };

      php.change_brokers_status(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_brokers_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  return (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Broker Name</GrayText>
            <BoldText>{clientData?.name}</BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Broker Username</GrayText>
            <BlueText>{clientData?.username}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>
              Referral{" "}
              <CopyToClipboard text={clientData?.signup_link}>
                <Button
                  // label="Edit"
                  icon="pi pi-copy"
                  outlined
                  aria-label="Filter"
                  size="small"
                  style={{
                    padding: 0,
                    height: "20px",
                    width: "40px",
                  }}
                />
              </CopyToClipboard>
            </GrayText>
            <BlueText>{clientData?.referral_code}</BlueText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Weekly Brokerage</GrayText>
            <GreenText
              style={{
                color:
                  clientData?.weekly_brokerage >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={clientData?.weekly_brokerage} />
            </GreenText>
          </FlexItem>
          <FlexItem>
            <GrayText>Weekly Sharing P/L</GrayText>
            <GreenText
              style={{
                color:
                  clientData?.weekly_profit_loss >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={clientData?.weekly_profit_loss} />
            </GreenText>
          </FlexItem>
        </FlexContainer>
      </Wrapper>
      <ButtonGrid>
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
          onClick={() => handleStatusButtonClick()}
        >
          {"ACTIVE"}
        </CreateClientButton>
      </ButtonGrid>
    </>
  );
}
